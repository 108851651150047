import AlgoliaDataWrapper from "@/components/AlgoliaDataWrapper";
import RailCarousel from "@/components/RailCarousel";
import useCurrentUser from "@/hooks/useCurrentUser";
import useCurrentUserDietaryRequirements from "@/hooks/useCurrentUserDietaryRequirements";
import getAlgoliaFacetFilters from "@/rails/lib/getAlgoliaFacetFilters";
import getAlgoliaFilters from "@/rails/lib/getAlgoliaFilters";
import getAlgoliaNumericFilters from "@/rails/lib/getAlgoliaNumericFilters";
import clsx from "clsx";
import { observer } from "mobx-react";

const Rail = observer(
  ({
    rail,
    hitsPerPage = 48,
    indexName = `${process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME}_popularity_desc`,
    shuffleSeed = -1,
    entriesToShow = 24,
    applyUserDietaryRequirements = true,
    dietaryRequirements = [], // this is only used in debugging /rails/[slug]
    debug = false,
    showHeading = true,
    theme = "dark",
    showChef = true,
    showSection = true,
    showSummary = false,
    className = "py-6 lg:py-8 xl:py-12",
    carouselClassName = undefined,
  }) => {
    // Fetch the current user
    const { isFetching } = useCurrentUser();

    // Gather the filters
    let facetFilters: string[] | string[][] =
      getAlgoliaFacetFilters(rail) || [];
    const filters: string = getAlgoliaFilters(rail) || "";
    const numericFilters: string[] = getAlgoliaNumericFilters(rail) || [];

    // If the user is logged in and has dietary requirements, we need to add them to the facet filters
    if (applyUserDietaryRequirements) {
      const { dietaryRequirementfacetFilters } =
        useCurrentUserDietaryRequirements();

      facetFilters = [...facetFilters, ...dietaryRequirementfacetFilters];
    }

    // If dietary requirements are passed in, we're debugging the rail at /rails/[slug]
    if (!applyUserDietaryRequirements && dietaryRequirements.length > 0) {
      const dietaryRequirementfacetFilters = [
        dietaryRequirements.map((dietaryRequirement) => {
          return `dietaryRequirements.title:${dietaryRequirement}`;
        }),
      ];

      facetFilters = [...facetFilters, ...dietaryRequirementfacetFilters];
    }

    const query = rail.keywordQuery ? rail.keywordQuery : undefined;

    return (
      <div className={clsx("Rail", className)}>
        <AlgoliaDataWrapper
          indexName={indexName}
          query={query}
          shouldFetch={!isFetching}
          facetFilters={facetFilters}
          filters={filters}
          postDateInPast
          numericFilters={numericFilters}
          responseFields={["hits"]}
          hitsPerPage={hitsPerPage}
          entriesToShow={entriesToShow}
          getRankingInfo={false}
          shuffleSeed={shuffleSeed}
          renderLoading={() => {
            return (
              <RailCarousel
                key={shuffleSeed}
                showHeading={showHeading}
                heading={rail.frontendTitle}
                summary={rail.summary}
                theme={theme}
                showChef={showChef}
                showSection={showSection}
                showSummary={showSummary}
                className={carouselClassName}
              />
            );
          }}
          render={(data) => {
            const entries = data.hits;

            return (
              <RailCarousel
                key={shuffleSeed}
                heading={rail.frontendTitle}
                summary={rail.summary}
                showSummary={showSummary}
                showHeading={showHeading}
                entries={entries}
                theme={theme}
                showChef={showChef}
                showSection={showSection}
                className={carouselClassName}
              />
            );
          }}
        />

        {debug && (
          <div className={clsx("p-12")}>
            <pre
              className={clsx("font-mono overflow-hidden whitespace-pre-wrap")}
            >
              {JSON.stringify(
                {
                  indexName,
                  hitsPerPage,
                  query,
                  facetFilters,
                  filters,
                  numericFilters,
                },
                null,
                2
              )}
            </pre>
          </div>
        )}
      </div>
    );
  }
);

export default Rail;
