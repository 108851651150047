import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

// Use the timezone plugin
dayjs.extend(utc);
dayjs.extend(timezone);

const getAlgoliaNumericFilters = (rail) => {
  // Set the timezone to London and get the start of the day
  const now = dayjs().tz("Europe/London").startOf("hour").valueOf();

  const numericFilters = [];

  // If the rail specifies a time filter less than, add it to the numeric filters
  if (rail.timeFilterLessThan) {
    numericFilters.push(`time<=${rail.timeFilterLessThan}`);
  }

  // Rails will always only every fetch posts that are in the past
  numericFilters.push(`postDate<=${now}`);

  return numericFilters;
};

export default getAlgoliaNumericFilters;
