import EntryCarousel from "@/components/EntryCarouselNew";
import clsx from "clsx";

const RailCarousel = ({
  heading = "",
  summary = null,
  entries = [],
  theme = "dark",
  className = "space-y-4 lg:space-y-6 2xl:space-y-8",
  showHeading = true,
  showChef = true,
  showSection = true,
  showSummary = false,
}) => {
  // if there are no entries, create 12 dummy entries
  if (entries.length === 0) {
    for (let i = 0; i < 12; i++) {
      entries.push({
        id: i,
        title: "",
        summary: "",
        sectionHandle: "",
      });
    }
  }

  return (
    <div className={clsx("RailCarousel", className)}>
      {!!heading && showHeading && (
        <div className={clsx("space-y-2 px-6 lg:space-y-4 lg:px-12")}>
          <h2 className={clsx("heading-sm--spatial")}>{heading}</h2>
          {summary && showSummary && (
            <p className={clsx("font-body text-sm lg:text-base")}>{summary}</p>
          )}
        </div>
      )}
      <div>
        <EntryCarousel
          entries={entries}
          theme={theme}
          carouselName={heading}
          showChef={showChef}
          showSection={showSection}
        />
      </div>
    </div>
  );
};

export default RailCarousel;
