import clsx from "clsx";
import DraggableScrollArea from "@/components/DraggableScrollArea";
import { StarIcon } from "@heroicons/react/24/solid";
import useCurrentUser from "@/hooks/useCurrentUser";

const TestimonialsSection = ({
  testimonials,
  heading = "Hear from our customers",
}) => {
  const { currentUser } = useCurrentUser();

  const isMobPlus = currentUser?.plan === 2;

  if (!testimonials || isMobPlus) {
    return null;
  }

  return (
    <div className="space-y-8 bg-[#F4F4F5] py-8 lg:space-y-12 lg:py-12">
      <div className="px-12">
        <h2
          className={clsx(
            "font-spatial text-2xl leading-6 lg:text-3xl xl:text-4xl 2xl:text-4xl"
          )}
        >
          {heading}
        </h2>
      </div>
      <DraggableScrollArea>
        <div className={clsx("flex space-x-6 px-6 lg:px-12")}>
          {testimonials.map(({ key, textContent, author, reviewRating }) => {
            return (
              <div
                key={key}
                className={clsx(
                  "w-80 snap-center snap-always space-y-4 rounded-xl bg-white p-6 lg:snap-none"
                )}
              >
                <_StarRating rating={reviewRating ?? "5"} />
                <div
                  className={clsx("font-body text-sm font-light lg:text-base")}
                  dangerouslySetInnerHTML={{
                    __html: textContent,
                  }}
                />
                {author && (
                  <p className="font-body text-xs text-black">{author}</p>
                )}
              </div>
            );
          })}
        </div>
      </DraggableScrollArea>
    </div>
  );
};

const _StarRating = ({ rating }) => {
  const ratingStr = rating.toString();
  const isHalfStar = rating.endsWith("5") && rating !== "5";
  const fullStars = rating
    ? parseInt(ratingStr[0], 10)
    : parseInt(ratingStr, 10);

  // Generate the star icons
  const stars = [];
  for (let i = 0; i < fullStars; i++) {
    stars.push(<StarIcon key={i} className={"h-6 w-6"} />);
  }

  if (isHalfStar) {
    stars.push(
      <StarIcon
        key={fullStars}
        className={"h-6 w-6"}
        style={{ clipPath: "polygon(0 0, 50% 0, 50% 100%, 0% 100%)" }}
      />
    );
  }

  return <div className="flex">{stars}</div>;
};

export default TestimonialsSection;
