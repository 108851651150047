import DraggableScrollArea from "@/components/DraggableScrollArea";
import EntryThumbnail from "@/components/EntryThumbnail";
import { pushEvent } from "@/lib/gtm";
import clsx from "clsx";

const EntryCarousel = ({
  entries,
  slideClassName = undefined,
  slideSize = "normal", // normal, large, small
  mobileSlideSize = "normal",
  theme = "dark",
  aspectRatio = "square",
  disableRecipeActions = false,
  thumbnailOverlay = false,
  showSection = false,
  showChef = true,
  disableLink = false,
  className = "flex space-x-6 px-6 lg:px-12",
  headerComponent = null,
  mobileTitleSize = "base",
  carouselName = null,
  showSummary = true,
}) => {
  // Slide className
  if (!slideClassName) {
    slideClassName = clsx("snap-center snap-always lg:snap-none", {
      "sm:!w-72 xl:!w-76": slideSize === "normal",
      "!w-[calc(100vw-7.5rem)] xs:!w-52": mobileSlideSize === "normal",
      "sm:!w-96 xl:!w-96": slideSize === "large",
      "!w-[calc(100vw-4.5rem)] xs:!w-60": mobileSlideSize === "large",
      "sm:!w-52 xl:!w-60": slideSize === "small",
      "!w-[calc(100vw-7.5rem)] xs:!w-44": mobileSlideSize === "small",
    });
  }

  return (
    <>
      <DraggableScrollArea>
        <div className={clsx("EntryCarousel", className)}>
          {!!headerComponent && <div>{headerComponent}</div>}
          {entries.map((entry, index) => {
            const isFirst = index === 0;
            const isLast = index === entries.length - 1;

            return (
              <div key={entry.id} className={clsx(slideClassName)}>
                <EntryThumbnail
                  entry={entry}
                  aspectRatio={aspectRatio}
                  disableRecipeActions={disableRecipeActions}
                  overlay={thumbnailOverlay}
                  showSection={showSection}
                  showChef={showChef}
                  disableLink={disableLink}
                  theme={theme}
                  priority={isFirst}
                  avoidCollisions={isLast}
                  saveRecipePopoverSide={isFirst ? "right" : "right"}
                  mobileTitleSize={mobileTitleSize}
                  showSummary={showSummary}
                  onClick={() => {
                    if (carouselName) {
                      pushEvent("carousel_entry_clicked", {
                        entry: entry.title,
                        carousel: carouselName,
                      });
                    }
                  }}
                />
              </div>
            );
          })}
        </div>
      </DraggableScrollArea>
    </>
  );
};

export default EntryCarousel;
