import Button from "@components/Button";
import Image from "next/image";
import useCurrentUser from "@/hooks/useCurrentUser";
import DraggableScrollArea from "@/components/DraggableScrollArea";
import cloudflareLoader from "@/lib/cloudflare-images";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";

const AboutMobPremiumBlock = ({
  blockData,
  rounded = false,
  mini = false,
  wrapperClass = "",
}) => {
  const { currentUser } = useCurrentUser();
  const plan = currentUser?.plan ?? null;

  const planMapping = {
    null: "guest",
    2: "premiumMember",
    3: "freeMember",
    4: "freeMember",
  };

  const userType = planMapping[plan];

  const data = blockData.find(
    (block) => block.memberGroup === userType && block.enabledForGroup
  );

  if (!data) {
    return null;
  }

  const { featureShowcase, heading, intro, pricingText } = data;

  return (
    <div className={clsx("@container", wrapperClass)}>
      <div
        className={clsx("relative  text-white", {
          "overflow-hidden md:rounded-2xl": rounded && !mini,
          "overflow-hidden lg:rounded-2xl": rounded && mini,
        })}
      >
        <Image
          src={`/images/neon-gradient.png`}
          alt=""
          layout="fill"
          objectFit="cover"
          role="presentation"
          className="pointer-events-none absolute left-0 top-0 block h-full w-full"
        />
        <div className="relative space-y-5 pb-8 pt-8 @[800px]:space-y-8 @[800px]:pt-10 @[1300px]:space-y-12 @[1300px]:pb-12 @[1300px]:pt-12 lg:space-y-10">
          <div
            className={clsx(
              "flex flex-col justify-between gap-0.5 px-6 @[800px]:gap-6 @[800px]:px-8 @[800px]:pt-0  @[1300px]:flex-row @[1300px]:gap-12 @[1300px]:px-12",
              {
                "pt-4 text-center @[800px]:text-left": !mini,
                "text-center @[800px]:text-left lg:text-left": mini,
              }
            )}
          >
            <div className="space-y-4 @[1300px]:max-w-[540px]">
              <h2 className="font-spatial text-2xl font-extrabold @[800px]:text-5xl">
                {heading}
              </h2>
              <div
                className="hidden text-lg font-normal lg:block"
                dangerouslySetInnerHTML={{ __html: intro }}
              />
            </div>
            <div
              className={clsx(
                "flex flex-col space-y-6 @[800px]:space-y-4 @[1300px]:items-center @[1300px]:text-center"
              )}
            >
              <p>{pricingText}</p>
              <div
                className={clsx(
                  "hidden max-w-[fit-content] flex-col gap-2 lg:flex",
                  {
                    "flex-col self-center @[800px]:max-w-none @[800px]:flex-row @[800px]:self-start":
                      !mini,
                  }
                )}
              >
                <_Buttons {...data} />
              </div>
            </div>
          </div>

          <div
            className={clsx("pb-2 @[800px]:pb-0", {
              "block lg:hidden": mini,
            })}
          >
            <_BlockCarousel blocks={featureShowcase} />
          </div>

          <div className="flex flex-col space-y-4 px-6 @[800px]:max-w-none @[800px]:flex-row @[800px]:space-x-2 @[800px]:space-y-0 @[800px]:self-start lg:hidden">
            <_Buttons {...data} />
          </div>
        </div>
      </div>
    </div>
  );
};

const _Buttons = ({
  signUpButtonText,
  signUpButtonLink,
  learnMoreButtonText,
  learnMoreButtonLink,
}) => {
  return (
    <>
      <Button
        label={signUpButtonText}
        theme="light"
        className="!h-auto !px-12 !py-3"
        labelClassName="!text-base font-medium font-sans !leading-none"
        href={signUpButtonLink}
        isSmall={false}
        onClick={undefined}
      />
      <Button
        label={learnMoreButtonText}
        theme="dark"
        className="!h-auto !border-white !px-12 !py-3"
        labelClassName="!text-base font-medium font-sans !leading-none"
        href={learnMoreButtonLink}
        isSmall={false}
        onClick={undefined}
      />
    </>
  );
};

const _BlockCarousel = ({ blocks }) => {
  return (
    <DraggableScrollArea>
      <div className={clsx("flex space-x-6 px-6 lg:px-12")}>
        {blocks.map((block) => {
          const image = block.image?.[0];

          return (
            <div
              key={block.id}
              className={clsx("w-72 snap-center snap-always")}
            >
              <div>
                <div
                  className={clsx(
                    "relative isolate aspect-16/11 overflow-hidden rounded-2xl bg-white/10"
                  )}
                >
                  {!!image && (
                    <Image
                      src={image.url}
                      layout="fill"
                      objectFit="cover"
                      objectPosition="center"
                      alt={image.title}
                      draggable={false}
                      loader={cloudflareLoader}
                    />
                  )}
                </div>
                <div className={clsx("flex items-start space-x-2 pt-4")}>
                  <CheckCircleIcon className="h-6 w-6 shrink-0" />
                  <div
                    className={clsx(
                      "font-sans text-base font-medium leading-5"
                    )}
                  >
                    {block.heading}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </DraggableScrollArea>
  );
};

export default AboutMobPremiumBlock;
