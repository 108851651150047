import FaqAccordionList from "@components/FaqAccordionList";
import clsx from "clsx";

const FaqsSection = ({ faqs, heading, text, divider = true }) => {
  if (!faqs || !faqs.length) {
    return null;
  }

  return (
    <div className="bg-zinc-100 px-6 lg:px-12">
      <div
        className={clsx(`grid gap-6 py-6 lg:grid-cols-12 lg:py-8 xl:py-12`, {
          "border-t border-[#D4D3D8]": divider,
        })}
      >
        <div className="col-span-12 space-y-6 lg:col-span-4 lg:pr-12 xl:pr-28">
          <h3 className="text-xl lg:text-2xl">{heading}</h3>
          <div
            className={clsx("body-text-sm [&_a]:transition [&_a]:ease-out")}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
        <div className="col-span-12 lg:col-span-8">
          <FaqAccordionList faqs={faqs} />
        </div>
      </div>
    </div>
  );
};

export default FaqsSection;
